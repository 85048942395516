// Here you can add other styles
#root {
  width: 100%;
  height: 100%;

}

#divIdToPrint {
  width: auto;
  background: white;
  min-height: 700px;
  // justify-content: ;
}

#space-for-add-image {
  background-color: #fff;
  height: auto;
  resize: both;
  overflow-y: scroll;
  overflow-x: scroll;
  // object-fit: scale-down;
  // image-rendering: auto;
}

.workArea {
  overflow-y: scroll;
  overflow-x: scroll;
}

.default-size-compass {
  background-color: transparent;
  resize: both;
  image-rendering: auto;
}

.space-for-add-image {
  background-color: #fff;
  top: 0px;
  width: 100%;
  display: flex;
  overflow-y: scroll;
  overflow-x: scroll;
  justify-content: center;
  height: 100%;
  resize: both;
  // object-fit: scale-down;
  // image-rendering: auto;
}
.space-for-add-pdf {
  background-color: #fff;
  width: 100%;
  justify-content: center;
}

// .to-hide-print {
//   display: none;
// }
@page {
  size: 210mm 297mm;
  margin: 0;
}

@media print {
  .to-hide-print {
    display: none;
  }

  .to-show-print {
    display: inline;
    margin-left: 50px;
    position: absolute;
  }
}

@page {
  size: auto;

  /* auto is the initial value */
  div#map {
    width: 100%;
    height: 100%;
    margin: 0mm;
    padding: 0mm;
  }
}

#compass-list-choosen {
  width: 100%;
  overflow-y: scroll;
  height: 720px;
}

#compass-image-list {
  // width: auto;
  margin: 5px;
  height: 43.2px;
}

body {
  font-family: sans-serif;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #999;
  z-index: 999;
  position: absolute;
  margin-top: 57.6px;
  width: 288px;
  background-color: white;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 65px;
  background-color: white;
  position: absolute;
  z-index: 999;
  max-height: 500px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(280px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions div:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}


.suggestions div:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.wrapper {
  display: flex;
  transition: margin 0.5s;
  margin: 0 0 0 0;
}

.wrapper.is-nav-open {
  display: flex;
  transition: margin 0.5s;
  margin: 0 0 0 -780px;

}

.wrapperFuniture {
  transition: margin 0.5s;
  margin: 0 0 0 10px;
}

.wrapperFuniture.is-nav-open {
  display: flex;
  transition: margin 0.5s;
  margin: 0 0 0 -790px;
}

.radioForm {
  position: fixed;
  width: 250px;
  margin-left: 50px;
}

.wrapperHouse {
  z-index: 99;
  transition: margin 0.5s;
  margin: 0 0 0 10px;
}

.wrapperHouse.is-nav-open {
  display: flex;
  z-index: 99;
  transition: margin 0.5s;
  margin: 0 0 0 -1030px;
}

//footer

.node-title.red {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll 0 bottom;
  display: block;
  margin-bottom: 26px;
  margin-top: 0;
  padding-bottom: 36px;
  text-align: left;
  text-transform: none;
  width: 100%;
}

#page-title-int {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll center bottom;
  display: block;
  margin-bottom: 26px;
  padding-bottom: 36px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.master-home {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-master-1.jpg") no-repeat scroll center center;
  float: left;
  height: 498px;
  width: 100%;
  margin-bottom: 30px;
}

.block-master-home {
  float: left;
  width: 100%;
}

.block-master-home .ads-items {
  float: left;
  height: 498px;
  position: relative;
  width: 100%;
}

.block-master-home .ads-items .image {
  bottom: 0;
  position: absolute;
  width: 35%;
}

.block-master-home .ads-items .inform {
  display: table;
  float: right;
  height: 498px;
  width: 50%;
  color: #fff;
}

.display-row {
  display: table-row;
  height: 100%;
}

.display-col {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.block-master-home .ads-items .inform h1.title {
  color: #e8e8e8;
  text-transform: uppercase;
}

.bxslidermaster,
.bxslidermaster li {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.block-master-home .ads-items .inform h2.title {
  color: #f00;
  font-size: 34px;
  width: 420px;
  line-height: 40px;
}

.linered {
  background: #c32f33 none repeat scroll 0 0;
  display: block;
  height: 1px;
  width: 80px;
}

.block-master-home .link-more {
  text-align: right;
}

.header-link {
  float: left;
  position: relative;
  width: 100%;
  text-align: center;
}

.icon-red {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll center 0;
  display: block;
  height: 17px;
  margin-bottom: 20px;
  width: 100%;
}

.link-page {
  border: 1px solid #ccc;
  font-size: 13px;
  padding: 3px 10px;
  position: absolute;
  right: 0;
  top: 15px;
}

.items-tuvan {
  float: none;
  margin: 0 auto;
  width: 265px;
}

.items-tuvan .title {
  font-size: 15px;
  margin: 6px 0;
  text-align: center;
}

.items-tuvan .des {
  padding: 0 15px;
  text-align: center;
  color: #515151;
}

.tu-van-home {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.owl-carousel {
  float: left;
}

.dang-ky-ban-tin {
  background: #b52025 none repeat scroll 0 0;
  float: left;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  color: #fff;
}

.simplenews-subscribe {
  margin: 0 auto;
  width: 615px;
}

.simplenews-subscribe .form-item.form-type-textfield.form-item-mail {
  /*display: inline-block;*/
  /*float: left;*/
  margin: 0;
  width: 100%;
}

.simplenews-subscribe .form-item.form-type-textfield.form-item-mail input.form-text {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  display: block;
  margin: auto;
  padding: 9px 60px;
  width: 304px;
  box-sizing: border-box;
}

.simplenews-subscribe .form-submit {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/btn-gui.jpg") no-repeat scroll center center;
  border: medium none;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-left: 10px;
  width: 119px;
}

.coppyright {
  background: #14171d none repeat scroll 0 0;
  color: #fff;
  float: left;
  padding-top: 5px;
  text-align: center;
  width: auto;
}

.footer-site {
  background: rgba(0, 0, 0, 0);
  background-color: black;
  /* url('https://img.freepik.com/free-vector/luxury-elegant-black-seamless-pattern_98292-5961.jpg?size=338&ext=jpg')
    repeat scroll center center; */
  color: #fff;
  /* float: left; */
  width: 100%;
}

.logo-footer {
  float: left;
  width: 340px;
}

.footer-site .address {
  float: left;
  margin-left: 20px;
  margin-top: 24px;
  width: 520px;
}

.social {
  float: right;
  margin-top: 24px;
}

.social a {
  border: 1px solid;
  border-radius: 50%;
  color: #eee;
  display: inline-block;
  font-size: 17px;
  height: 40px;
  margin-left: 7px;
  padding-top: 8px;
  text-align: center;
  width: 40px;
}

.footer-bottom {
  float: left;
  margin: 10px 0;
  width: 100%;
}

.menu-footer-b {
  float: left;
  width: 885px;
}

.menu-footer-b ul.menu {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.menu-footer-b ul.menu li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 25%;
}

.menu-footer-b ul.menu li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.menu-footer-b ul.menu li a:hover {
  color: #c1282e;
}

.menu-footer-b ul.menu li ul {
  margin-top: 15px;
}

.menu-footer-b ul.menu li ul li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.menu-footer-b ul.menu li ul li a {
  text-transform: none;
  font-weight: normal;
}

.doi-tac {
  float: right;
  margin-right: 30px;
  width: 150px;
}

.doi-tac h2.title {
  font-size: 15px;
  margin-top: 0;
  color: #fff;
}

.slide-doi-tac {
  background: #232731 none repeat scroll 0 0;
  text-align: center;
}

.doi-tac-items {
  float: left;
  height: 110px;
  text-align: center;
  width: 100%;
  display: table;
}

.doi-tac-items img {
  max-height: 110px;
}

.tai-lieu-home {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-tailieupt.jpg") no-repeat scroll center center;
  float: left;
  height: 471px;
  margin-bottom: 30px;
  width: 100%;
}

.items-tailieu {
  float: left;
  width: 100%;
  color: #fff;
}

.items-tailieu h1.title {
  margin-top: 40px;
  text-transform: uppercase;
}

.items-tailieu h1.title a {
  color: #fff;
}

.items-tailieu h3.title a {
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  font-size: 26px;
}

.items-tailieu .image {
  float: left;
  margin-right: 30px;
  margin-top: 30px;
  max-height: 350px;
  overflow: hidden;
  width: 400px;
}

.items-tailieu .text {
  float: right;
  width: 600px;
}

.items-tailieu .link-more {
  text-align: right;
}

.doi-tac-home-content {
  float: left;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

.doi-tac-home-content .linered {
  background: #c32f33 none repeat scroll 0 0;
  display: inline-block;
  height: 1px;
  width: 80px;
}

.doi-tac-home-content h2.title {
  color: #2c3946;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  margin: 15px 0 0;
  text-transform: uppercase;
}

.doi-tac-items-home {
  display: table;
  float: left;
  height: 110px;
  width: 100%;
}

.slide-doi-tac-home {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.san-pham-home {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.link-more-f {
  float: left;
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.link-more-f a {
  background: #c1282d none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 6px 19px;
  text-transform: uppercase;
}

.link-more-f a:hover {
  color: #fff;
  opacity: 0.8;
}

.items-sanpham {
  border: 1px solid #ccc;
  float: none;
  height: 355px;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  width: 245px;
}

.items-sanpham h3.title {
  font-size: 15px;
  margin: 5px 0 0;
}

.items-sanpham .linered {
  display: inline-block;
}

.items-sanpham .price {
  font-weight: 600;
}

.owl-prev {
  color: #666;
  font-size: 50px;
  position: absolute;
  left: -20px;
  top: 40%;
}

.owl-next {
  color: #666;
  font-size: 50px;
  position: absolute;
  right: -20px;
  top: 40%;
}

.coltwo #content {
  float: right;
  width: 830px;
}

.left {
  float: left;
  width: 260px;
}

.left .block {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

h2.title.bgred.center {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px 0;
  text-align: center;
}

.content.bg-xam {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  width: 100%;
}

.left ul.menu,
.left ul.menu li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.left ul.menu li a {
  border-bottom: 1px solid #e0e0e0;
  color: #757575;
  float: left;
  padding: 15px;
  width: 230px;
}

.left ul.menu li a:hover,
.left ul.menu li a.active {
  background: #d8dade;
  color: #000;
}

.block.tuvanphongthuy {
  background: #ecedef none repeat scroll 0 0;
  padding: 10px;
  width: 240px;
}

.left h2.title {
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0;
  text-align: center;
  text-transform: uppercase;
}

.left .items-tuvan {
  border-bottom: 1px solid #e0e0e0;
  float: none;
  margin: 0 auto 15px;
  padding-bottom: 15px;
  width: 100%;
}

.left .items-tuvan:last-child {
  border-bottom: none;
  margin: 0 auto 0;
  padding-bottom: 0;
}

#page-title-int-not {
  font-size: 24px;
  margin-top: 10px;
  text-transform: uppercase;
}

.items-san-pham {
  border: 1px solid #ccc;
  float: left;
  height: 355px;
  margin-bottom: 26px;
  margin-left: 26px;
  padding: 6px;
  text-align: center;
  width: 245px;
}

.items-san-pham.first {
  margin-left: 0;
}

.items-san-pham h3.title {
  font-size: 15px;
  margin: 5px 0 0;
}

.items-san-pham .linered {
  display: inline-block;
}

.items-san-pham .price {
  font-weight: 600;
}

.node-type-san-pham h1#page-title-int-not {
  display: none;
}

.node-left {
  border: 1px solid #ccc;
  float: left;
  padding: 10px;
  text-align: center;
  width: 505px;
}

.node-right {
  float: right;
  width: 280px;
}

.node-right .node-title {
  font-size: 26px;
  font-weight: 500;
}

.node-right .gia {
  color: #000;
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.node-right .lead {
  color: #8f8f8f;
  float: left;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
}

.cart-add-form {
  float: left;
  width: 100%;
}

.cart-add-form label {
  display: inline-block;
  font-weight: normal;
  width: 75px;
}

.cart-add-form .form-text {
  border: 1px solid #ccc;
  color: #999;
  display: inline-block;
  text-align: center;
  width: 50px;
}

.cart-add-form .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

.node .body-field {
  float: left;
  margin-top: 20px;
  width: 100%;
}

h2.b-title {
  margin-bottom: 6px;
  text-transform: uppercase;
}

.node .icon-red {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll 0 0;
}

.san-pham-khac {
  float: left;
  margin-top: 30px;
  width: 100%;
}

.items-news {
  float: left;
  margin-bottom: 30px;
  margin-left: 33px;
  width: 348px;
  height: 420px;
  overflow: hidden;
}

.items-news.first {
  margin-left: 0px;
}

.items-news .lead {
  color: #515151;
  text-align: justify;
}

.page-node #page-title-int {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll 0 bottom;
  text-align: left;
}

.bailienquan .items-news .lead {
  display: none;
}

.bailienquan .items-news {
  height: 300px;
}

#messages {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.view-empty {
  color: #999;
  display: block;
  font-size: 20px;
  padding: 50px 0;
  text-align: center;
}

.cau-hoi-tu-van {
  background: #c1282e none repeat scroll 0 0;
  color: #fff;
  float: left;
  width: 100%;
}

.form-cau-hoi {
  background: #c1282e none repeat scroll 0 0;
  float: left;
  width: 40%;
}

.noi-dung-form {
  float: right;
  margin-right: 30px;
  width: 360px;
}

.noi-dung-form label,
.grippie {
  display: none;
}

.form-text,
select,
textarea {
  border: 1px solid #ccc;
  min-width: 100px;
  padding: 6px;
  width: auto;
}

.noi-dung-form .form-text {
  width: 97%;
}

.noi-dung-form h2.title {
  margin: 30px 0 20px;
  text-align: center;
  color: #fff;
}

.noi-dung-form .form-submit {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/btn-gui.jpg") no-repeat scroll center center;
  border: medium none;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-left: 10px;
  width: 119px;
  float: right;
}

.cau-hoi-thuong-gap {
  background: #303846 none repeat scroll 0 0;
  float: left;
  margin-left: 0;
  padding-left: 3%;
  padding-right: 3%;
  text-align: center;
  width: 54%;
}

.cau-hoi-thuong-gap h2.title {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 0;
  color: #fff;
}

.cau-hoi-thuong-gap .linered {
  background: #c32f33 none repeat scroll 0 0;
  display: inline-block;
  height: 1px;
  width: 80px;
}

.list-faq {
  float: left;
  margin-top: 10px;
  text-align: justify;
  width: 100%;
}

.list-faq .icon {
  border: 3px solid #fff;
  border-radius: 50%;
  float: left;
  font-size: 38px;
  font-weight: 600;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: center;
  width: 40px;
}

.list-faq .text {
  border-bottom: 1px solid #222;
  float: right;
  font-size: 14px;
  padding-bottom: 10px;
  width: 88%;
}

.list-faq:last-child .text {
  border-bottom: none;
  padding-bottom: 10px;
}

.message-lits {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}

.su-kien-phong-thuy-home {
  float: left;
  margin-bottom: 40px;
  width: 100%;
}

.items-sukien {
  float: left;
  margin-left: 5px;
  position: relative;
  line-height: 0;
}

.items-sukien.item-1,
.items-sukien.item-4,
.items-sukien.item-7 {
  margin-left: 0px;
}

.items-sukien .layout {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-sk.png") repeat-x scroll center bottom;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.items-sukien .info {
  bottom: 0;
  color: #fff;
  line-height: 24px;
  padding: 20px 3%;
  position: absolute;
  text-align: center;
  width: 94%;
}

.items-sukien .info h3.title {
  margin-bottom: 6px;
}

.items-sukien .info .date {
  color: #c1282d;
  font-size: 13px;
}

.lich-hoc-phong-thuy {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

.lich-hoc-phong-thuy h2.title {
  text-align: center;
  color: #2c3946;
}

.lich-hoc-phong-thuy h2.title .red {
  color: #c1282d;
}

.lich-hoc-pt {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  width: 100%;
}

.lich-hoc {
  background: #e9e9e9 none repeat scroll 0 0;
  float: left;
  height: 470px;
  width: 302px;
}

.lich-hoc h3.title {
  margin-top: 30px;
  text-align: center;
}

.lich-hoc .scrollbar {
  float: left;
  height: 382px;
  margin-left: 20px;
  width: 262px;
  overflow: hidden;
}

.scrollbar>ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.scrollbar ul li {
  border-bottom: 1px dashed #e0e0e1;
  font-size: 15px;
  list-style: outside none none;
  margin: 0;
  padding: 12px 0;
}

.scrollbar ul li .icon {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-lh.png") no-repeat scroll 0 center;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  width: 19px;
  display: none;
}

.scrollbar ul li a {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-lh.png") no-repeat scroll 0 3px;
  display: inline-block;
  padding-left: 25px;
}

.scrollbar ul li strong.date-time {
  color: #2c3946;
  display: inline-block;
  margin-right: 15px;
}

.scrollbar ul li a:hover strong.date-time {
  color: #c1282d;
}

.khoa-hoc-slide-home .items-content-f .link>a:hover {
  background: #303846;
}

.khoa-hoc-slide-home {
  float: right;
  margin-bottom: 40px;
  position: relative;
  width: 750px;
}

.khoa-hoc-slide-home .ctab-wrap {
  float: left;
  width: 100%;
}

.khoa-hoc-slide-home .ctab-wrap .ctab {
  border: 1px solid #cfcfcf;
  float: left;
  margin-bottom: 30px;
  margin-top: 20px;
}

.khoa-hoc-slide-home .ctab-wrap .ctab a {
  border-right: 1px solid #cfcfcf;
  color: #929292;
  float: left;
  font-size: 17px;
  padding: 12px 25px;
}

.khoa-hoc-slide-home .ctab-wrap .ctab a:last-child {
  border-right: none;
}

.khoa-hoc-slide-home .ctab-wrap .ctab a.active {
  background: #fff;
  color: #2c3946;
  text-decoration: none;
}

.khoa-hoc-slide-home .items-content {
  background: #fff none repeat scroll 0 0;
  display: inline-block;
  float: none;
  margin: 0 auto;
  width: 233px;
}

.khoa-hoc-slide-home .owl-carousel .owl-item {
  text-align: center;
}

.khoa-hoc-slide-home .owl-carousel .owl-item .items-content {
  text-align: left;
}

.khoa-hoc-slide-home .items-content-info {
  color: #515151;
  display: block;
  height: 125px;
  padding: 0 15px;
}

.khoa-hoc-slide-home .items-content-info h3.title {
  height: 48px;
  overflow: hidden;
}

.khoa-hoc-slide-home .items-content-f {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  padding: 0;
  width: 100%;
}

.khoa-hoc-slide-home .items-content-f .link>a {
  color: #fff;
  float: right;
  padding: 10px;
  text-transform: uppercase;
}

.khoa-hoc-slide-home .items-content-f .price {
  float: left;
  padding: 10px;
}

.items-sanpham .image,
.items-tuvan .image,
.items-san-pham .image {
  position: relative;
}

.xem-detail {
  position: absolute;
  top: 35%;
  width: 100%;
  z-index: 999;
  display: none;
  text-align: center;
}

.items-sanpham:hover .xem-detail,
.items-tuvan:hover .xem-detail,
.items-san-pham:hover .xem-detail {
  display: block;
}

.xem-detail a.xem-chi-tiet {
  background: #c1282d none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  padding: 5px 25px;
  text-transform: uppercase;
}

.xem-detail a.xem-chi-tiet:hover {
  background: #2c3946 none repeat scroll 0 0;
}

.link-all {
  font-size: 18px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 34px;
  width: 130px;
}

.link-all a {
  color: #929292;
}

.view.view-lich-hoc-phong-thuy {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.view.view-lich-hoc-phong-thuy table {
  width: 100%;
}

.view.view-lich-hoc-phong-thuy table th {
  background: #c1282e none repeat scroll 0 0;
  border: 1px solid #ccc;
  color: #fff;
  padding: 5px;
  font-size: 13px;
}

.view.view-lich-hoc-phong-thuy table td {
  font-size: 13px;
  border: 1px solid #ccc;
  padding: 5px;
}

table tr.odd {
  background-color: #e9e9e9;
}

table tr.even {
  background-color: #fff;
}

.node-type-hoc-phong-thuy #main-wrapper {
  margin-top: 0;
}

.node-type-hoc-phong-thuy #main-wrapper .contentner {
  width: 100%;
}

.khoa-hoc-out {
  margin: 0 auto;
  width: 1110px;
}

.khoa-hoc-int {
  float: left;
  width: 100%;
}

.left-node {
  background: #ff0008 none repeat scroll 0 0;
  color: #fff;
  float: left;
  width: 302px;
}

.left-node .content {
  display: block;
  padding: 20px;
}

.left-node .content h2.title {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  margin-top: 0;
}

.left-node .items-l {
  border-top: 1px solid #ff8084;
  display: block;
  float: left;
  padding: 15px 0;
  width: 100%;
}

.left-node .items-l .fa {
  display: inline-block;
  float: left;
  font-size: 35px;
  margin-right: 10px;
  text-align: center;
  width: 35px;
}

.left-node .items-l span {
  display: inline-block;
  float: left;
  margin-top: 5px;
}

.left-node .link>a {
  background: #303846 none repeat scroll 0 0;
  color: #fff;
  float: left;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.right-node {
  float: right;
  width: 780px;
}

.right-node h2.title {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll center bottom;
  float: left;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-top: 36px;
  padding-bottom: 26px;
}

.right-node .content {
  float: left;
  width: 100%;
}

.right-node .field.field-name-body {
  float: left;
  text-align: justify;
  width: 420px;
}

.right-node .field.field-name-field-image {
  float: right;
  width: 330px;
}

.chuong-trinh-hoc {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  margin-top: 30px;
  padding: 20px 0;
  width: 100%;
  padding-bottom: 0;
}

.chuong-trinh-hoc .khoa-hoc-int {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-intro.png") no-repeat scroll 0 bottom;
}

.node .chuong-trinh-hoc .icon-red {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll center 0;
}

.chuong-trinh-hoc .link {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.chuong-trinh-hoc .link>a {
  background: #ff0008 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px;
  text-transform: uppercase;
}

.giang-vien-chuong-trinh-hoc {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
}

.khoa-hoc-khac.bailienquan {
  float: left;
  margin-top: 10px;
  width: 100%;
}

.khoa-hoc-khac.bailienquan h2.b-title {
  text-align: center;
}

.pager.pager-load-more li a {
  background: #c1282e none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  margin-bottom: 40px;
  padding: 5px 30px;
  text-transform: uppercase;
}

.khoa-hoc-khac.bailienquan .icon-red {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll center 0;
}

.node-type-hoc-phong-thuy #main-wrapper .giang-vien-chuong-trinh-hoc .contentner {
  width: 1110px;
}

.giang-vien-chuong-trinh-hoc .master-home {
  margin: 0;
}

.body-giang-vien {
  float: left;
  margin-top: 10px;
  text-align: justify;
  width: 100%;
}

.scroll-content {
  display: block;
  padding-right: 20px;
}

.node .giang-vien-chuong-trinh-hoc .icon-red {
  background: rgba(0, 0, 0, 0) url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png") no-repeat scroll center 0;
  position: absolute;
  z-index: 999;
}

.items-khoa-hoc {
  background: #ecedef;
  float: left;
  height: 388px;
  margin-bottom: 30px;
  margin-left: 33px;
  overflow: hidden;
  width: 348px;
}

.items-khoa-hoc.first {
  margin-left: 0;
}

.items-khoa-hoc .img {
  float: left;
  height: 222px;
  position: relative;
  width: 100%;
}

.items-khoa-hoc .price-link {
  bottom: 0;
  position: absolute;
}

.items-khoa-hoc .price-link .price {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  float: left;
  font-weight: 600;
  padding: 8px 15px;
  width: auto;
}

.items-khoa-hoc .price-link a {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  float: left;
  font-weight: 600;
  padding: 8px 15px;
  width: auto;
  text-transform: uppercase;
}

.items-khoa-hoc .price-link a:hover {
  background: #303846 none repeat scroll 0 0;
}

.items-khoa-hoc .info {
  float: left;
  padding: 15px;
  width: 318px;
}

.items-khoa-hoc .info h3.title {
  float: left;
  margin: 0;
  width: 155px;
}

.items-khoa-hoc .info .right-info {
  color: #515151;
  float: right;
  width: 160px;
}

.form-one {
  border: 1px solid #999;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 #999;
  display: table;
  font-size: 13px;
  margin: 10px auto 40px;
  padding: 20px;
  width: 600px;
}

.form-one .form-text,
.form-one select,
.form-one textarea {
  border: 1px solid #ccc;
  padding: 6px;
  max-width: 100%;
  width: auto;
  min-width: 100px;
}

.form-one .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  float: left;
  font-weight: 600;
  padding: 8px 15px;
  text-transform: uppercase;
  width: auto;
}

.form-one h3 {
  text-transform: uppercase;
}

.form-one label {
  color: #515151;
}

select {
  background: #fff url(http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/select.png) no-repeat right;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  padding-right: 25px;
}

select::-ms-expand {
  display: none;
}

.form-cart {
  float: left;
  margin-bottom: 40px;
  width: 100%;
}

.form-cart table {
  border: 1px solid #ccc;
  width: 100%;
}

.form-cart table th,
.form-cart table td {
  padding: 6px;
}

.form-cart table tr {
  border: 1px solid #ccc;
}

.form-cart table tr input.form-text {
  border: 1px solid #ccc;
  width: 40px;
  text-align: center;
}

.form-cart table .colall {
  text-align: right;
}

.form-cart .form-action {
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: right;
  width: 100%;
}

.form-cart .form-action .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  padding: 5px 10px;
}

.empty {
  color: #515151;
  margin-bottom: 40px;
  text-align: center;
}

.empty a {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin-top: 10px;
  padding: 4px 15px;
  text-transform: uppercase;
  width: auto;
}

.empty a:hover {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
}

.form-don-hang {
  border: 1px solid #ccc;
  display: block;
  padding: 20px;
}

.form-cart .left-form {
  float: left;
  font-size: 13px;
  width: 45%;
}

.form-cart .form-right {
  background: #eee none repeat scroll 0 0;
  border: 1px solid #ccc;
  float: right;
  padding: 10px 2%;
  width: 48%;
}

.form-cart .form-right label {
  font-weight: normal;
}

.form-cart .form-right .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
}

.menu-mobile {
  display: none;
}

.mobile-over {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.menu-mobile-site {
  background: #eee none repeat scroll 0 0;
  display: none;
  float: left;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 250px;
  z-index: 999999;
}

.header-menu {
  background: #ccc none repeat scroll 0 0;
  float: left;
  width: 100%;
}

.header-menu a {
  color: #666;
  float: right;
  font-size: 29px;
  line-height: 18px;
  margin: 9px;
}

.menu-mobile-site>ul {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.menu-mobile-site>ul li {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 5px 0;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.menu-mobile-site>ul li ul li:last-child {
  border-bottom: none;
}

.menu-mobile-site ul li ul {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
  display: none;
}

.menu-mobile-site>ul li a {
  font-size: 13px;
  font-weight: 600;
  padding-left: 15px;
  text-transform: uppercase;
}

.menu-mobile-site>ul li ul li a {
  text-transform: none;
  font-weight: 500;
}

.menu-mobile-site>ul li .fa {
  float: right;
  font-size: 20px;
}

.menu-mobile-site>ul li .fa {
  float: right;
  font-size: 20px;
  margin-right: 6px;
}

.menu-mobile-site>ul li .fa.fa-angle-up {
  display: none;
}

#_quick-link {
  background: none;
  left: -200px;
  padding: 10px 2px;
  position: fixed;
  top: calc(20% - 78px);
  width: 130px;
  z-index: 999;
}

#_quick-link>div {
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 3px;
}

#_quick-link .text {
  float: left;
  font-size: 13px;
  width: 100%;
}

#_quick-link .bg {
  background: #b2b2b2 none repeat scroll 0 0;
  border-radius: 50%;
  display: inline-block;
  height: 54px;
  padding-top: 8px;
  width: 62px;
}

#_quick-link a:hover .bg {
  background: #c1282d none repeat scroll 0 0;
}

.view-more {
  float: left;
  margin: 10px 0;
  width: 100%;
}

.view-more>a {
  color: #fff;
  text-transform: uppercase;
}

.contact-map {
  float: right;
  width: 50%;
}

.contact-form {
  float: left;
  width: 40%;
}

.contact-form label {
  display: none;
}

.contact-form .form-submit {
  background: #c1282e none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 6px 20px;
}

.view.view-lich-hoc th {
  background: #d72136 none repeat scroll 0 0;
  border-right: 1px solid white;
  color: white;
  height: 37px;
  text-align: center;
}

.view.view-lich-hoc tbody tr.odd {
  background: #ddd none repeat scroll 0 0;
}

.view.view-lich-hoc tbody tr {
  border-bottom: 2px solid white;
}

.view.view-lich-hoc tbody tr.even {
  background: #f2f2f2 none repeat scroll 0 0;
}

.view.view-lich-hoc tr td {
  border-right: 1px solid white;
  font-size: 13px;
  height: 37px;
  text-align: center;
}

.view.view-lich-hoc tbody tr td:first-child {
  background: #153980 none repeat scroll 0 0;
  color: #fff;
}

.view.view-lich-hoc table {
  border-collapse: collapse;
  width: 100%;
}

.view.view-lich-hoc {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.teaser-node {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}

.teaser-node .image {
  float: left;
  margin-right: 20px;
  width: 160px;
}

.teaser-node .node-title {
  margin-top: 0;
}

div.captcha {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 1024px) {
  .contentner {
    margin: 0 auto;
    width: 1000px;
  }

  .khoa-hoc-slide-home {
    float: right;
    margin-bottom: 40px;
    position: relative;
    width: 688px;
  }

  .khoa-hoc-slide-home .ctab-wrap .ctab a {
    padding: 12px 24px;
  }

  hoc-slide-home .items-content {
    width: 220px;
  }

  .items-sanpham {
    width: 230px;
  }

  .items-tailieu .text {
    width: 566px;
  }

  .menu-footer-b {
    float: left;
    width: 812px;
  }

  .items-tuvan {
    width: 250px;
  }

  .items-news {
    margin-left: 26px;
    width: 315px;
  }

  .cau-hoi-thuong-gap {
    background: #303846 none repeat scroll 0 0;
    float: left;
    margin-left: 0;
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
    width: 54%;
  }

  .items-khoa-hoc {
    margin-left: 16px;
    width: 322px;
  }

  .items-khoa-hoc .img {
    height: 206px;
  }
}


.c-main>.container-fluid,
.c-main>.container-sm,
.c-main>.container-md,
.c-main>.container-lg,
.c-main>.container-xl {
  padding-left: 0px;
  padding-right: 0px;
}

.header-setting-buttons {
  position: absolute;
  right: 0px;
}

// .select-search__option :hover {
//   background-color: green;
//   border-width: 0px;
//   color: #fae042;
//   cursor: pointer;
//   font-weight: 700;
// }

// .select-search__options {
//   padding-left: 30px
// }
.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  padding-left: 0px;
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
  display: block;
  height: 36px;
  padding-left: 5px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: rgb(201, 200, 200);
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value {
  background-size: 11px;
}


/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}


.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 500px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}
